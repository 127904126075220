import React, {useContext, useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import ScrollableSite from "../ScrollableSite";
import './App.scss';
import { store } from './store';
import GoogleFontLoader from "react-google-font-loader";
import {CSSTransition} from "react-transition-group";
import MetaTags from 'react-meta-tags';
import { Component as HistorySaver } from "../../hooks/useHistorySaver";
import LightboxedPages from "../LightboxedPages";

function injectQueryStringIntoHashBangUrls(domOrTxt) {
  const el = typeof domOrTxt === 'string'
    ? ((html) => {
      const div = document.createElement('div');
      div.innerHTML = html;
      return div;
    })(domOrTxt)
    : domOrTxt;

  Array.from(el.querySelectorAll('a[href]'))
    .filter((a) => {
      const h = a.href.indexOf('#');
      // if ? after # && ? not before #
      return a.href.indexOf('?', h) > 0 && a.href.lastIndexOf('?', h) < 0;
    })
    .forEach(a => a.search = '?_');
  return el.innerHTML;
}

function App() {
  const [activePath, setActivePathInner] = useState(undefined);
  const { dispatch, state } = useContext(store);

  useEffect(() => {
    // When the page is loaded, get the user's selected language.
    // Like the previous site, language is set on a browser-basis, not in the URL.
    // This may change in a different way.
    const lang = window.localStorage.getItem('smpLang');
    const name = window.localStorage.getItem('smpLangName');
    if (lang && name) {
      dispatch({type: 'setLanguage', value: lang, name});
    }
  }, [dispatch]);

  useEffect(() => {
    // Get translations for this and deeper pages.
    (async () => {
      const req = await window.fetch(`/api/cms/routes/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json',
          mode: 'no-cors'
        },
      });
      if (!req.ok || req.status > 400) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error(`Could not fetch routes items`);
      }
      const json = await req.json();
      dispatch({
        type: 'setRoutes',
        value: json,
      });
    })();
  }, [dispatch]);

  useEffect(() => {
    // Get translations for this and deeper pages.
    (async () => {
      const req = await window.fetch(`/api/cms/translations/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json',
          mode: 'no-cors'
        },
      });
      if (!req.ok || req.status > 400) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error(`Could not fetch translations items`);
      }
      const json = await req.json();
      dispatch({
        type: 'setTranslations',
        value: json,
      });
    })();
  }, [dispatch]);

  useEffect(() => {
    // Fetch translations and store.
    if (!state.lang) {
      // Get from local storage (if any).
      const lang = window.localStorage.getItem('smpLang');
      const name = window.localStorage.getItem('smpLangName');
      if (lang && name) {
        state.lang = lang;
        dispatch({type: 'setLanguage', value: lang, name});
      }
    }
    if (!state.routes || !Object.keys(state.routes).length) {
      return;
    }

    (async function() {
      const pages = Object.values(state.routes).map(r => r.slug).filter((c, i, a) => {
        return i === a.indexOf(c);
      });
      const content = await Promise.all(
        pages.map(async (page) => {
          let slug = page;
          if (page === 'home') {
            slug = '';
          }
          const req = await window.fetch(`/api/${slug}?json=1&json_lang=${state.lang}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              mode: 'no-cors'
            }
          });
          if (!req.ok || req.status > 400) {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error(`Could not fetch content`);
          }
          const content = await req.json();
          content.seo = {
            keywords: content.keywords,
            description: content.description,
            title: content.title,
          };
          (content.alineas || [])
            .filter(alinea => typeof alinea.txt === 'string')
            .forEach((alinea) => {
              alinea.txt = injectQueryStringIntoHashBangUrls(alinea.txt);
            });
          return {
            page,
            content,
          };
        }),
      );
      const value = content.reduce((obj, pair) => {
        obj[pair.page] = pair.content;
        return obj;
      }, {});
      dispatch({type: 'setContent', value});
    })();

  }, [state.lang, dispatch, state.routes]);

  function setActivePath(path) {
    setActivePathInner(path);
    // To prevent MacOS Safari from overscrolling the page (bouncing it up and down) we
    // have to disable scrolling vertically on this browser.
    if(typeof document !== 'undefined') {
      // Not using `toggle` for browser compatibility.
      document.body.classList[path ? 'add' : 'remove']('scrollableSiteEnabled');
    }
  }

  if (!state.routes || !Object.keys(state.routes || {}).length) {
    return null;
  }

  const langMap = {
    "4": "Nederlands",
    "5": "English",
    "6": "Deutsch",
  };

  return (
    <Router basename={'/'}>
      <HistorySaver />
      <GoogleFontLoader fonts={[
        {
          font: 'Lato',
          weights: [300, 400, 700, 900],
        },
        {
          font: 'Roboto',
          weights: [300, 400, '400i', 700],
        },
      ]} />

      {state.meta && <MetaTags>{state.meta}</MetaTags>}

      {/* To handle 404s, add all routes and if none match, render a
          wildcard route */}
      <Switch>
        {Object.entries(state.routes).map(([route, {name, slug, lang}]) => {
          let exact = true;
          if (name === 'Portfoliocategorien') {
            exact = false;
          }
          if (name === 'Portfolio case') {
            exact = false;
          }
          return <Route path={route} exact={exact} key={`${slug}`}>{((match, ...routeProps) => {
            if (match !== null && state.lang !== lang) {
              dispatch({type: 'setLanguage', value: lang, name: langMap[lang] || ''});
            }
            return null;
          })}</Route>
        })}
        <Route>{
          (props) => {
            props.history.replace('/');
          }
        }</Route>
      </Switch>

      <CSSTransition
        in={activePath !== null}
        timeout={{appear: 0, enter: 100, exit: 100}}
        classNames="scrollable-transition"
        mountOnEnter={false}
        unmountOnExit={false}
        appear={true}
      >
        <ScrollableSite setActivePath={setActivePath} setLastPath={() => {}} />
      </CSSTransition>

      <CSSTransition
        in={activePath === null}
        timeout={{appear: 1000, enter: 1000, exit: 1000}}
        classNames="lightbox-transition"
        mountOnEnter={true}
        unmountOnExit={true}
        appear={true}
      >
        <LightboxedPages />
      </CSSTransition>
    </Router>
  );

}

export default App;
