import React, {useContext, useEffect, useMemo, useState} from 'react';
import styles from './CasePage.module.scss';
import {Link} from "react-router-dom";
import Audio from "../Audio";
import VimeoVideo from "../VimeoVideo";
import ScreenEffectText from "../ScreenEffectText";
import FullWidthBackground from "../FullWidthBackground";
import {store} from "../App/store";
import useSeo from "../../hooks/useSeo";
import useTranslations from "../../hooks/useTranslations";
import useTranslatableUrls from "../../hooks/useTranslateableUrls";

function CasePage({children, ...props}) {
  const [cmsContent, setCmsContent] = useState(null);
  const [loaded, setIsLoaded] = useState(false);
  const { state } = useContext(store);

  const __ = useTranslations();

  const portfolioId = parseInt(props?.match?.params?.caseId, 10) || 19;

  useEffect(() => {
    setIsLoaded(false);
    (async () => {
      try {
        const req = await window.fetch(`/api/portfolio_case/as-json?json=${portfolioId}&json_lang=${state.lang}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            mode: 'no-cors'
          }
        });
        if (!req.ok || req.status > 400) {
          // noinspection ExceptionCaughtLocallyJS
          throw new Error(`Could not fetch portfolio item ${portfolioId}`);
        }
        const json = await req.json();
        setCmsContent(json);
        setIsLoaded(true);
      } catch (error) {
        console.error(error);
        setCmsContent(false);
      }
    })();

    return () => {
      // Any cleanup that is needed.
    }
  }, [portfolioId, state.lang]);

  const item = cmsContent?.portfolio[0] || null;

  const seo = useMemo(() => {
    if (!item) {
      return {
        title: 'Case',
        description: '',
      };
    }
    return {
      title: `${item.title} - Case`,
      description: item.subtitle,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  useSeo(seo);
  const urls = useTranslatableUrls();

  const selectedTeamMembers = (cmsContent?.portfolio[0]?.team || '').split(', ');
  const team = cmsContent?.team.filter((teamMember) => {
    return selectedTeamMembers.includes(teamMember.data.name);
  }).map((teamMember) => {
    return {
      id: teamMember.id,
      image: teamMember.data.img,
      name: teamMember.data.name,
    };
  }) || [];

  const {
    making_text: makingOfText,
    making_title: makingOfTitle,
    making_video: makingOfVimeoUrl,
    soundCloud: soundCloudId,
    subtitle,
    title,
    txt: cmsTxt = '',
    img: image,
    poster,
    vimeo = [],
    test_title: testemonialTitle,
    test_text: testemonialText,
    test_img: testemonialImage
  } = item || {};
  // const isCase = cmsContent?.portfolio[0].case === 'Y';
  const hasMakingOfText = typeof makingOfText === 'string' && makingOfText.trim().length > 0;
  const hasTestemonialText = typeof testemonialText === 'string' && testemonialText.trim().length > 0;
  const bg = /*cmsContent?.portfolio[0]?.header_img ||*/ cmsContent?.portfolio[0]?.img || null;

  return (
    <>
      <FullWidthBackground bg={bg} />
      <div className={styles.case}>
        <ScreenEffectText className={styles.primaryTitle}>
          <h1>
            {title}
          </h1>
        </ScreenEffectText>
        {!!vimeo.length && (
          <div className={styles.primaryEmbed}>
            <div className={styles.videoEmbed}>
              <h3>{vimeo[0].title}</h3>
              <VimeoVideo src={vimeo[0].video} title={vimeo[0].title} />
            </div>
          </div>
        )}
        <div className={styles.forcedBreak} />
        <div className={styles.content}>
          <h2>{__('Over deze case')}</h2>
          {subtitle && subtitle.trim().length && <h3>{subtitle}</h3>}
          <div dangerouslySetInnerHTML={{__html: cmsTxt}} />
        </div>
        {!!team.length && (
          <div className={styles.teamMembers}>
            <h2>Team</h2>
            {team.map((member) => (
              <Link key={member.id} to={urls('team')} className={styles.teamMember} style={{
                backgroundImage: `url('${member.image}')`,
                }}>
                <span>{member.name}</span>
              </Link>
            ))}
          </div>
        )}
        <div className={styles.forcedBreak} />

        {makingOfTitle && (hasMakingOfText || makingOfVimeoUrl) && (
          <div className={styles.content}>
            <h2>{makingOfTitle ? makingOfTitle : 'The making of...'}</h2>
            {hasMakingOfText && <div dangerouslySetInnerHTML={{__html: makingOfText}} />}
            {!hasMakingOfText && makingOfVimeoUrl && (
              <div className={`${styles.embeds} ${styles.videoEmbed}`}>
                <VimeoVideo src={makingOfVimeoUrl} />
              </div>
            )}
          </div>
        )}
        {hasMakingOfText && makingOfVimeoUrl && (
          <div className={`${styles.embeds} ${styles.videoEmbed}`}>
            <VimeoVideo src={makingOfVimeoUrl} />
          </div>
        )}

        {testemonialTitle && (hasTestemonialText || testemonialImage) && (
          <div className={styles.content}>
            <h2>{testemonialTitle ? testemonialTitle : 'Testemonial'}</h2>
            {hasTestemonialText && <div dangerouslySetInnerHTML={{__html: testemonialText}} />}
            {!hasTestemonialText && testemonialImage && (
              <div className={`${styles.embeds} ${styles.imageEmbed}`}>
                <img src={`/api${testemonialImage}`} alt={''} />
              </div>
            )}
          </div>
        )}
        {hasTestemonialText && testemonialImage && (
          <div className={`${styles.embeds} ${styles.imageEmbed}`}>
            <img src={`/api${testemonialImage}`} alt={''} />
          </div>
        )}

        {!!soundCloudId && (
          <div className={`${styles.embeds} ${styles.audioEmbeds}`}>
            <h2>Audio</h2>
            <Audio trackId={soundCloudId} poster={poster || image} />
          </div>
        )}
        {!!vimeo.length && (
          <div className={`${styles.embeds} ${!!soundCloudId ? '' : styles.noAudioEmbeds}`}>
            <h2>Video</h2>
            {vimeo.map((currentVimeoVideo, i) => (
              <div key={`${i}-${currentVimeoVideo.video}`} className={styles.videoEmbed}>
                <h3>{currentVimeoVideo.title}</h3>
                <VimeoVideo src={currentVimeoVideo.video} title={currentVimeoVideo.title} />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}

export default CasePage;
