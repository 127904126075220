import React, {useContext} from 'react';
import {Route, useLocation} from 'react-router-dom';
import LightboxPage from "../LightboxPage";
import ContentPage from "../ContentPage";
import StudioPage from "../StudioPage";
import TeamPage from "../TeamPage";
import CasePage from "../CasePage";
import Button from "../Button";
import PortfolioCategoryPage from "../PortfolioCategoryPage";
import useHistorySaver from "../../hooks/useHistorySaver";
import useTranslations from "../../hooks/useTranslations";
import {store} from "../App/store";
import useTranslatableUrls from "../../hooks/useTranslateableUrls";

function LightboxedPages() {
  const __ = useTranslations();
  const urls = useTranslatableUrls();
  const location = useLocation();
  const previous = useHistorySaver();
  const { state } = useContext(store);

  // Depending on what kind of page we are, we choose a different "back" link.
  const pathName = location.pathname || '/';
  // Normally, go back to the slider page we came from.
  let prev = previous.slider;
  if (pathName.includes(urls('portfolio_case'))) {
    // Go back to the portfolio page we were on.
    prev = previous.portfolio;
  }

  const blacklist = [
    'home',
    'over_ons',
    'showreel',
    'library',
    'contact',
  ];

  return (
    <LightboxPage backTo={prev}>
      {Object.entries(state.routes).filter(([, route]) => {
        return !blacklist.includes(route.slug);
      }).map(([route, {lang, name, slug}]) => {

        const content = state.content?.[slug] || {};
        const extra = {};
        let extraContent = null;

        if (blacklist.includes(slug)) {
          return null;
        }

        if (slug === 'licensing') {
          extraContent = (
            <div className="centered">
              <Button highlight={true}
                      src={"https://smp.sourceaudio.com/#!albums"}
                      target="_blank" rel="noopener noreferrer">
                &gt;&gt;&gt; {__('Ga naar SMP Library')} &lt;&lt;&lt;
              </Button>
            </div>
          );
        }
        if (slug === 'team') {

          return (<Route key={`cms_${lang}_${slug}`} path={route} strict={false} exact={true}>
            <TeamPage seo={content.seo} />
          </Route>);
        }
        if (slug === 'onze_studio') {

          return (<Route key={`cms_${lang}_${slug}`} path={route} strict={false} exact={true}>
            <StudioPage seo={content.seo} />
          </Route>);
        }
        if (slug === 'portfoliocategorien') {

          return (
            <React.Fragment key={`cms_${lang}_${slug}_pair`}>
              <Route key={`cms_${lang}_${slug}_search`} path={`${route}/search`} strict={false} exact={true}>{
                (props) => {
                  if (!props.match) {
                    return null;
                  }
                  props.match.params.category = 'search';
                  return <PortfolioCategoryPage {...props} />
                }
              }</Route>
              <Route key={`cms_${lang}_${slug}`} path={`${route}/:category`} strict={false} exact={true}>{
                (props) => {
                  if (!props.match || props.match?.params?.category === 'search') {
                    return null;
                  }
                  return <PortfolioCategoryPage {...props} />
                }
              }</Route>
            </React.Fragment>
          );
        }
        if (slug === 'portfolio_case') {

          return (
            <Route key={`cms_${lang}_${slug}`} path={`${route}/:caseId`} strict={false} exact={true}>{
              (props) => {
                if (!props.match) {
                  return null;
                }
                return <CasePage {...props} />
              }
            }</Route>
          );
        }

        if (slug === 'wat_we_doen') {
          extra['className'] = 'watWeDoenPage';
        }
        return (<Route key={`cms_${lang}_${slug}`} path={route} strict={false} exact={true}>
          <ContentPage title={content.title} items={content.alineas} seo={content.seo} {...extra}>
            {extraContent}
          </ContentPage>
        </Route>);
      })}
    </LightboxPage>
  );

}

export default LightboxedPages;
