import React, {forwardRef, useContext, useEffect, useState} from "react";
import styles from './Library.module.scss';
import { After, Before, Content, Outer } from '../ScrollableSite';
import AlbumTiles from "./AlbumTiles";
import Button from '../Button';
import imacImage from "./imac-placeholder.png";
import imacImageWebp from "./imac-placeholder.webp";
import {CSSTransition} from "react-transition-group";
import useTranslations from "../../hooks/useTranslations";
import {store} from "../App/store";
import useTranslatableUrls from "../../hooks/useTranslateableUrls";

function LibraryPage(props, ref) {

  const __ = useTranslations();
  const urls = useTranslatableUrls();

  const { state } = useContext(store);

  const content = state.content?.library || {};

  const [ embedsDisabled, setEmbedsDisabled ] = useState(false);

  const onCurrentSlide = (props.isInView);

  useEffect(() => {
    if (!onCurrentSlide && !embedsDisabled) {
      setEmbedsDisabled(true);
    } else if(onCurrentSlide && embedsDisabled) {
      setEmbedsDisabled(false);
    }
  }, [onCurrentSlide, embedsDisabled]);

  const first = content?.alineas?.[0] || {};

  const bulletPoints = content?.alineas?.slice(1).map((alinea) => {
    return {
      id: alinea.id,
      title: alinea.title,
    };
  }) || [];

  const soundCloudTracks = [];
  if (content?.slides) {
    const { data } = content.slides[0];
    if (data.link && data.img2) {
      soundCloudTracks.push({
        id: data.link,
        poster: '/api' + data.img2,
      });
    }
    if (data.link2 && data.img3) {
      soundCloudTracks.push({
        id: data.link2,
        poster: '/api' + data.img3,
      });
    }
    if (data.link3 && data.img4) {
      soundCloudTracks.push({
        id: data.link3,
        poster: '/api' + data.img4,
      });
    }
    if (data.link4 && data.img5) {
      soundCloudTracks.push({
        id: data.link4,
        poster: '/api' + data.img5,
      });
    }
  }

  return (
      <Outer className={styles.outer}>
        <Before />
        <Content className={styles.outerContent} ref={ref}>
          <div className={styles.Library}>
            <div className={styles.halfWrapper}>
              <div className={styles.content}>
                <h1>{first.title}</h1>
                <div dangerouslySetInnerHTML={{__html: first.txt}} />
              </div>
              <div className={styles.buttons}>
                <Button highlight={true}
                  src={"https://smp.sourceaudio.com/#!albums"}
                  target="_blank" rel="noopener noreferrer">
                  &gt;&gt;&gt; {__('Ga naar SMP Library')} &lt;&lt;&lt;
                </Button>
                <Button src={urls('licensing')}>{__('Music licensing')}</Button>
              </div>
            </div>
            <div className={styles.halfWrapper}>
              <AlbumTiles soundCloudTracks={soundCloudTracks} bulletPoints={bulletPoints}
                disabled={embedsDisabled} />
              <div className={styles.image}>
                <picture>
                  <source srcSet={imacImageWebp} type="image/webp" />
                  <img alt="" src={imacImage} />
                </picture>
              </div>
            </div>
          </div>
        </Content>
        <CSSTransition in={props.active}
          timeout={1500}
          classNames="library-page-bg"
          mountOnEnter={false}
          unmountOnExit={false}
          appear={false}>
          <After className={styles.after}>
            <div className={styles['bg-large']} />
          </After>
        </CSSTransition>
      </Outer>
  );
}

export default forwardRef(LibraryPage);
