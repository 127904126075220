import React, {forwardRef, useContext} from "react";
import styles from './Contact.module.scss';
import { After, Before, Content, Outer } from '../ScrollableSite';
import TagLine from './ContactTagline';
import ContactDetail from "./ContactDetail";
import Contactlinks from "./ContactLinks";
import ContactContent from "./ContactContent";
import Socials from "./Socialmedia";
import {store} from "../App/store";


function ContactPage(props, ref) {

  const { state } = useContext(store);

  const content = state.content?.contact || {};
  const alineas = content?.alineas || [];

  return (
      <Outer className={styles.outer}>
        <Before />
        <Content ref={ref}>
          <a className={styles.pinPoint} href="https://goo.gl/maps/v1GfV319mQpWa7ry8" target="_blank" rel="noopener noreferrer">
            <span>SMP | Amsterdam</span>
            Luchtvaartstraat 2B <br/>
            1059 CA Amsterdam
          </a>
          <div className={styles.Contact}>
            <div className={styles.contactWrap}>
              <div className={styles.small}>
                <ContactContent content={alineas[0] || {}} />
                <ContactDetail content={alineas[1] || {}} />
                <Contactlinks content={alineas[2] || {}} />
              </div>
            </div>
            <div className={styles.placeName}>
              <TagLine>
                Amsterdam
              </TagLine>
            </div>
            <Socials/>
          </div>
          <div className={styles.bg}/>
        </Content>
        <After />
      </Outer>
  );
}

export default forwardRef(ContactPage);
