import React, {useContext, useEffect, useState} from 'react';
import styles from './PortfolioCategoryPage.module.scss';
import SearchInput from "../SearchInput";
import useTranslations from "../../hooks/useTranslations";
import {store} from "../App/store";
import Button from "../PortfolioPage/PortfolioItemButton";
import debounce from "lodash/debounce";
import useTranslatableUrls from "../../hooks/useTranslateableUrls";

function ucFirst(str)
{
  return typeof str !== 'string' ? str : (str[0].toUpperCase() + str.slice(1));
}

async function getPortfolioItems(lang, category, searchTerm) {
  const req = await window.fetch(`/api/loadPortfolioItems?json=1&json_lang=${lang}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json',
      mode: 'no-cors'
    },
    body: `wrapperNumber=0&filter=${encodeURIComponent(category || '')}&searchInput=${encodeURIComponent(searchTerm || '')}`
  });
  if (!req.ok || req.status > 400) {
    throw new Error(`Could not fetch portfolio items`);
  }
  const json = await req.json();
  /*
   * Categorize output. Input: array of items. Output:
   * [ { title: 'someCategory', items: arrayOfItems }, ... ]
   */
  const findOrMakeCat = (categories, category) => {
    const match = categories.find(c => c.title === category);
    if (!match) {
      const newCategory = {
        title: category,
        items: [],
      };
      categories.push(newCategory);
      return newCategory;
    }
    return match;
  };
  return json.reduce((output, current) => {
    // Find existing category.
    const categories = current.category.split(',');
    categories.forEach((currentCategory) => {
      const cat = findOrMakeCat(output, currentCategory);
      cat.items.push(current);
    })
    return output;
  }, []);
}

function PortfolioCategoryPage({children, match, ...props}) {
  const __ = useTranslations();
  const { state, dispatch } = useContext(store);
  const { lang } = state;
  const [content, setContent] = useState([]);
  const [pageError, setPageError] = useState(null);
  const [focussedItem, setFocussedItem] = useState(null);
  const urls = useTranslatableUrls();

  const isSearchPage = match.params.category === 'search';

  const searchTerm = !isSearchPage ? null : state.searchTerm;
  const category = isSearchPage ? null : match.params.category;

  useEffect(() => {
    (async () => {
      // One of these two will be filled, depending on which one, a different
      // query is executed.
      const items = await getPortfolioItems(lang, category, searchTerm);
      setContent(items);
    })().catch((e) => {
      setPageError(e);
      setContent([]);
    });
  }, [lang, searchTerm, category, setContent]);

  const setSearchTerm = function(str) {
    dispatch({type: 'setSearchTerm', value: str});
    if (!isSearchPage) {
      const url = `${urls('portfoliocategorien')}/search`;
      props.history.push(url);
    }
  }

  const debouncedSetSearchTermInner = debounce(function(value) {
    setSearchTerm(value);
  }, 500);

  if (pageError) {
    return (
      <>
        <div>
          <h3>{__('Foutmelding')}</h3>
          <p>{pageError.message}</p>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.searchBar}>
        <SearchInput onInput={e => debouncedSetSearchTermInner(e.value)} onSubmit={e => setSearchTerm(e.value)}
          value={state.searchTerm || ''}>
          {(isSearchPage && match.params?.searchTerm) || null}
        </SearchInput>
      </div>
      {!content || !content.length ? (
        <div className={styles.noContent}>
          {__('Geen resultaten')}
        </div>
      ) : (content.map(({title, items, ...categoryObj}, i) => (
        (!isSearchPage && title !== category) ? null :
        <div key={title} className={styles.contentCategory}>
          <h3>{ucFirst(__(title))}</h3>
          <div className={styles.grid}>
            {items.map((item, i) => {
              const key = `${item.id}`;
              const classNames = [
                styles.gridItem,
                focussedItem === key ? styles.activeGridItem : null,
              ].filter(Boolean).join(' ');
              const vimeo = !item.vimeo?.length ? null : (
                item.vimeo[0].video
              );
              return (
                <div key={key} className={classNames} style={{
                  backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, .85), transparent 30%), url('https://smp.nl/${item.img || item.poster}')`,
                }} onClick={(ev) => {
                  setFocussedItem(key);
                }} data-key={key}>
                  <div className={styles.gridItemInner}>
                    <div className={styles.gridItemTitleAndControl}>
                      <h3>{item.title}</h3>
                      <Button src={`${urls('portfolio_case')}/${item.id}`}>{__('Lees meer')}</Button>
                    </div>
                    {(vimeo && focussedItem === key) ? (
                      <iframe className={styles.gridItemFrame}
                              title={`Video ${item.title}`}
                              src={vimeo}
                              data-url={vimeo}
                              loading="lazy"
                              allow="autoplay"
                              allowFullScreen={true} />
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )))}
    </>
  );

}

export default PortfolioCategoryPage;
