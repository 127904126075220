import React, {forwardRef, useContext} from 'react';
import { After, Before, Content, Outer } from '../ScrollableSite';
import styles from './AboutUsPage.module.scss';
import Button from "../Button";
// import poster from "./poster.jpg";
import {CSSTransition} from "react-transition-group";
import useTranslations from "../../hooks/useTranslations";
//import {useLocation} from "react-router-dom";
import {store} from "../App/store";
import Video from "../Video";
import useTranslatableUrls from "../../hooks/useTranslateableUrls";

function AboutUsPage({activeSlide, name: slideName, children, activePageIsAfterThis, slideDirection, ...props}, ref) {
  //const location = useLocation();
  const { state } = useContext(store);
  const __ = useTranslations();
  const urls = useTranslatableUrls();

  const afterClassName = [
    styles.after,
    slideDirection > 0 ? styles.animationLtr : styles.animationRtl,
  ].filter(Boolean).join(' ');

  const alinea = state.content?.over_ons?.alineas?.[0] || {};
  const videoSrc = alinea.alinea_video ? `https://smp.nl${alinea.alinea_video}` : null;
  const poster = alinea.alinea_img ? `/api${alinea.alinea_img}` : null;

  return (
    <Outer className={styles.outer}>
      <Before className={styles.before} />
      <Content className={styles.content} ref={ref}>
        <div className={styles.secondary}>
          <Video
            src={videoSrc} srcs={[]}
            poster={poster}
          />
        </div>
        <div className={styles.primary}>
          <h1>{alinea.title}</h1>
          <div dangerouslySetInnerHTML={{__html: alinea.txt}} />

          <div className={styles.buttonList}>
            <Button className={styles.button} src={urls('team')}>
              {__('Meet the members')}
            </Button>

            <Button className={styles.button} src={urls('wat_we_doen')}>
              {__('Wat we doen')}
            </Button>

            <Button className={styles.button} src={urls('onze_studio')}>
              {__('Onze studio')}
            </Button>
        </div>
        </div>

        {children}
      </Content>

      <After className={afterClassName}>
        <CSSTransition in={props.active}
          timeout={1500}
          classNames="about-page-bg-large"
          mountOnEnter={false}
          unmountOnExit={false}
          appear={false}>
          <div className={`${styles['bg-large']} about-page-bg-large`} />
        </CSSTransition>
      </After>
    </Outer>
  );
}

export default forwardRef(AboutUsPage);
